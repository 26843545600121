<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 class="tit_section">세금계산서현황</h2>
      </div>
      <div class="body_section">
        <TransactionReportListFilterBar
          ref="filterBar"
          :statusList="statusList"
          :defaultKeywordInputText="defaultKeywordInputText"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultStatusId="defaultStatusId"
          :excelBtn="false"
          :keywordDatas="keywordList"
          @onClickSearch="onClickSearch"
        />
        <TableHead>
          <template v-slot:headRight>
            <div class="group_form">
              <SelectBoxPageRange
                :currentMaxRowCount.sync="size"
                @update:currentMaxRowCount="onChangeRowCount"
              />
            </div>
          </template>
        </TableHead>
        <ListPage
          :currentPageIndex="currentPageIndex"
          :isHorizontalScroll="false"
          :list="dataList"
          :maxPaginationCount="maxPaginationCount"
          :maxRowCount="size"
          :scrollWidth="'1900px'"
          :totalPageCount="totalPageCount"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <!-- 발행상태 -->
            <col style="width: 100px" />
            <!-- 승인상태 -->
            <col style="width: 80px" />
            <!-- 거래명세서번호 -->
            <col />
            <!-- 발금익 -->
            <col style="width: 74px" />
            <!-- 세금계산서번호 -->
            <col style="width: 190px" />
            <!-- 거래명세금액 -->
            <col style="width: 120px" />
            <!-- 세금계산서금액 -->
            <col style="width: 120px" />
            <!-- 구매담당자 -->
            <col style="width: 120px" />
          </template>
          <template v-slot:tr>
            <th>발행상태</th>
            <th>승인상태</th>
            <th>거래명세서번호</th>
            <th>발급일</th>
            <th>세금계산서번호</th>
            <th>거래명세서금액</th>
            <th>세금계산서금액</th>
            <th>구매담당자</th>
          </template>
          <template v-slot:row="slotProps">
            <TransactionReportListLine :rowData="slotProps.rowData" />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td class="td_empty" colspan="8">세금계산서 내역이 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>

    <template v-slot:popup />
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import ListPage from "@/components/shared/ListPage";

import TransactionReportListFilterBar from "@/components/user/transactionReport/list/TransactionReportListFilterBar.vue";
import TransactionReportListLine from "@/components/user/transactionReport/list/TransactionReportListLine.vue";

import PageMixin from "@/mixins/PageMixin";
import ListPageMixin from "@/mixins/ListPageMixin";

export default {
  name: "TransactionReportList",
  components: {
    PageWithLayout,
    TableHead,
    SelectBoxPageRange,
    ListPage,

    TransactionReportListFilterBar,
    TransactionReportListLine,
  },
  mixins: [PageMixin, ListPageMixin],
  props: {},
  data() {
    return {
      API_PATH: this.$apiPath.TRANSACTION,
      defaultStatusId: "",
      statusList: [
        { code: "", desc: "전체", name: "전체", value: "" },
        { code: "SUCCESS", desc: "발행", name: "발행", value: "SUCCESS" },
        { code: "FAIL", desc: "미발행", name: "미발행", value: "FAIL" },
      ],
      defaultStatusId: "",
      keywordList: [
        { id: "transactionReportCid", code: "transactionReportCid", desc: "거래명세서번호" },
        { id: "taxInvoiceNo", code: "taxInvoiceNo", desc: "세금계산서번호" },
      ],
      defaultKeywordSelectedId: "transactionReportCid",
      defaultKeywordInputText: "",
    };
  },
  computed: {},
  created() {
    const params = this.checkUrlQuery();
    this.getDataInit(params);
  },
  methods: {
    checkUrlQuery() {
      const { query } = this.$route;
      const objPaging = this.checkQueryPaging(query);
      const objKeyword = this.checkQueryKeywords(query, this.keywordList);
      const objIssue = this.checkQueryIssue(query);
      const objDates = this.checkQueryDates(query, "startDate", "endDate");
      const ret = Object.assign({}, objPaging, objKeyword, objDates, objIssue);

      return ret;
    },
    getFilterbarParams(objData, isForExcel) {
      const { keywordSelectedId, keywordInputText, startDt, endDt, status, isMyWork } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }
      if (startDt && endDt) {
        params["startDate"] = startDt;
        params["endDate"] = endDt;
      }
      if (status) {
        params.issue = status;
      }

      return params;
    },
    checkQueryIssue(query) {
      const { issue } = query;

      if (!issue) return {};

      this.defaultStatusId = issue;

      return { issue: issue };
    },
  },
};
</script>
