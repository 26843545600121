<template>
  <FilterBarBase :excelBtn="false" @onClickSearch="onClickSearch" @onClickReset="onClickReset">
    <dl class="w152">
      <dt>발생상태</dt>
      <dd>
        <SelectBox class="w152" :dataList="statusList" :value.sync="statusId" />
      </dd>
    </dl>
    <dl>
      <dt>계산서발행기간</dt>
      <dd>
        <DateRangeQuickSelect :dates.sync="dates" />
      </dd>
      <dt>검색어</dt>
      <dd>
        <div class="group_form">
          <SelectBox class="w208" :dataList="keywordDatas" :value.sync="keywordSelectedId" />
          <Input class="w425" :value.sync="keywordInputText" @onEnterKey="onClickSearch" />
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import Input from "@/components/common/input/Input";
import CheckboxOnlyOne from "@/components/common/checkbox/CheckboxOnlyOne";
import SelectBox from "@/components/common/selectBox/SelectBox";

import DateRangeQuickSelect from "@/components/common/calendar/DateRangeQuickSelect";

import FilterBarMixin from "@/mixins/FilterBarMixin";

export default {
  name: "TransactionReportListFilterBar",
  components: {
    FilterBarBase,
    Input,
    CheckboxOnlyOne,
    SelectBox,
    DateRangeQuickSelect,
  },
  mixins: [FilterBarMixin],
  props: {
    defaultDates: Array,

    excelBtn: Boolean,
  },
  data() {
    return {
      dates: this.defaultDates,
    };
  },
  methods: {
    onClickSearch() {
      let obj = this.getCommonFilterbarData();

      this.$emit("onClickSearch", obj);
    },
    onClickReset() {
      this.onClickResetFilterBar();
    },
  },
};
</script>
