<template>
  <tr>
    <!-- 발행상태 -->
    <td>
      {{ rowData.issueName }}
    </td>
    <!-- 승인상태 -->
    <td>
      {{ computedAgreeYnText }}
    </td>
    <!--거래명세서번호-->
    <td class="td_ellip" :data-ellip="rowData.transactionReportCid">
      <router-link :to="toPath" class="link_subject align_center">
        {{ rowData.transactionReportCid }}
      </router-link>
    </td>
    <!--발급일-->
    <td>
      {{ rowData.taxInvoiceDate ? moment(rowData.taxInvoiceDate).format("YYYY-MM-DD") : "-" }}
    </td>
    <!--세금계산서번호-->
    <td :data-ellip="rowData.taxValidationName" :class="computedTaxValidation">
      {{ computedTaxInvoiceNo | nullToDash }}
    </td>
    <!--금액-->
    <td class="align_right">
      {{ rowData.totalPrice | currency }}
    </td>
    <!--세금계산서금액-->
    <td class="align_right">
      {{ rowData.ntsTotalAmount | currency }}
    </td>
    <!--구매담당자-->
    <td>
      {{ rowData.regId }}
    </td>
  </tr>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import ListLineMixin from "@/mixins/ListLineMixin";
import { makePathWithQuery } from "@/utils/urlUtils";

export default {
  name: "TransactionReportListLine",
  components: {
    ListRow,
  },
  mixins: [ListLineMixin],
  props: {
    rowData: Object,
  },
  data() {
    return {};
  },
  computed: {
    toPath() {
      const { transactionReportCid } = this.rowData;
      const path = makePathWithQuery(
        this.$routerPath.TRANSACTIONREPORT_VIEW,
        transactionReportCid,
        this.$route.query,
      );
      return path;
    },
    computedTaxInvoiceNo() {
      let ret = "";
      if (this.rowData.taxInvoiceNo) {
        const first = this.rowData.taxInvoiceNo.substr(0, 8);
        const secend = this.rowData.taxInvoiceNo.substr(8, 8);
        const third = this.rowData.taxInvoiceNo.substr(16, 8);
        ret = `${first}-${secend}-${third}`;
      } else {
        ret = null;
      }
      return ret;
    },
    computedTaxValidation() {
      const { issue, taxValidationName } = this.rowData;
      let ret = "";
      if (issue === "FAIL") {
        ret = "is-nonvalid";
      }
      return ret;
    },
    computedAgreeYnText() {
      let ret = "";
      if (this.rowData.agreeYn === "") {
        ret = "대기";
      } else if (this.rowData.agreeYn === false) {
        ret = "수정요청";
      } else {
        ret = "승인";
      }
      return ret;
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.is-valid {
  color: blue;
}
.is-nonvalid {
  color: red;
  font-weight: 500;
}
.is-unknown {
  color: #607d8b;
}
</style>
